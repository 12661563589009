///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  ButtonGroup,
  Spinner,
  Form,
  CustomListGroup,
  CustomListGroupItem,
  InputGroup,
  CloseButton,
  FormText,
  Row,
  Col,
  ListGroupItemText,
} from "@ibiliaze/reactstrap";
import { Api } from "@ibiliaze/react-base";
import { FullPage, Section } from "@ibiliaze/react-base";
import { saveAs } from "file-saver";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "../../actions/alert";
import { getQuotes, postQuote, putQuote, deleteQuote } from "../../actions/quotes";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
import getRandomString from "../../utils/random";
import c from "../../utils/constants";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Quote from "../PDF/Quote";
import List from "../Layout/List";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  // DO NOT CHANGE THESE, BUT YOU CAN ADD
  model: "gpt-4",
  temprature: 1,
  top_p: 0.5,
  max_tokens: 1400,
  name: "",
  address: "",
  postcode: "",
  time: "",
  details: "",
  email: "",
  tel: "",
  rci: "yes",
  instalments: 3,
  depositPercentage: 15,
  invoice: false,
  accepted: false,
  projectDate: "",
};

const quoteSearchDefault = { name: "", ref: "", postcode: "", email: "", tel: "" };

const QuotesPage = ({ quotes, getQuotes, postQuote, putQuote, deleteQuote, setAlert }) => {
  // State
  const [page, setPage] = useState(1);
  const [offcanvas, setOffcanvas] = useState(false);
  const [generated, setGenerated] = useState("");
  const [ref, setRef] = useState("");
  const [quoteUrl, setQuoteUrl] = useState("");
  const [details, setDetails] = useState([]);
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [quoteSearch, setQuoteSearch] = useState({ ...quoteSearchDefault });
  const [quoteSearchType, setQuoteSearchType] = useState("");
  const [edit, setEdit] = useState({ quote: false, detail: false });
  const [loading, setLoading] = useState({
    generate: false,
    sendEmail: false,
    getQuotes: false,
    saveQuote: false,
    deleteQuote: false,
  });

  // Ref
  const componentRef = useRef();

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // Location
  const { search } = useLocation();

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));
  const onQuoteSearchChange = (e) => setQuoteSearch((c) => ({ ...c, [e.target.name]: e.target.value }));

  // onClick functions
  const onPageClick = async (e, index) => {
    try {
      setLoading((c) => ({ ...c, getQuotes: true }));
      e.preventDefault();
      const goToPage = index < 1 ? 1 : index;

      setPage(goToPage);
      await getQuotes(
        `?${quoteSearchType}=${quoteSearch[quoteSearchType]}&limit=10&skip=${10 * (goToPage - 1)}`
      );
      setLoading((c) => ({ ...c, getQuotes: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getQuotes: false }));
      console.error(e);
    }
  };

  const onQuoteClick = (quote) => {
    try {
      if (!!quote) {
        setEdit((c) => ({ ...c, quote: true }));
        setInputs({ _id: quote._id, ...quote.inputs });
        setDetails([...quote.details]);
        setRef(quote.ref);
      } else {
        setEdit((c) => ({ ...c, quote: false }));
        setInputs({ ...inputsDefault });
        setDetails([]);
        setRef("");
      }
      toggleOffcanvas();
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, quote: false }));
      setInputs({ ...inputsDefault });
      setDetails([]);
      setRef("");
      toggleOffcanvas();
    }
  };

  const onGenerateClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, generate: true }));
      const res = await api.post("/api/quote/generate", { ...inputs, template: "generateQuote" });
      setGenerated(res.data.quote);
      setLoading((c) => ({ ...c, generate: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, generate: false }));
      console.error(e);
    }
  };

  const onSaveDetailClick = (_) => {
    try {
      if (!!edit.detail) {
        const arr = details;
        arr[edit.detail - 1] = { input: inputs.details, details: generated };

        setDetails((c) => [...arr]);
      } else {
        setDetails((c) => [...c, { input: inputs.details, details: generated, ref: getRandomString(4) }]);
      }

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit((c) => ({ ...c, detail: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, detail: false }));
      console.error(e);
    }
  };

  const onDetailClick = (d, i) => {
    try {
      setEdit((c) => ({ ...c, detail: i + 1 }));
      setInputs((c) => ({ ...c, details: d.input }));
      setGenerated(d.details);
    } catch (e) {
      setEdit((c) => ({ ...c, detail: false }));
      console.error(e);
    }
  };

  const onDeleteDetailClick = (i) => {
    try {
      const arr = details;
      arr.splice(i, 1);
      setDetails([...arr]);

      setInputs((c) => ({ ...c, details: "" }));
      setGenerated("");
      setEdit((c) => ({ ...c, detail: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, detail: false }));
      console.error(e);
    }
  };

  const onCreateClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, saveQuote: true }));

      const fileName = `${inputs.invoice ? "Invoice" : "Quote"} for ${inputs.name} - ${
        inputs.postcode
      } - ${ref}`;

      const res = await api.post("/api/pdf/create", {
        ...inputs,
        dom: componentRef.current.innerHTML,
        fileName,
      });

      const fileUrl = res.data.url;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      saveAs(blob, fileName);

      setQuoteUrl(fileUrl);

      setLoading((c) => ({ ...c, saveQuote: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveQuote: false }));
      setQuoteUrl("");
      console.error(e);
    }
  };

  const onSearchClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, getQuotes: true }));
      await getQuotes(`?${quoteSearchType}=${quoteSearch[quoteSearchType]}&limit=10&skip=${10 * (page - 1)}`);
      toggleOffcanvas();
      setLoading((c) => ({ ...c, getQuotes: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getQuotes: false }));
      console.error(e);
    }
  };

  const onEmailClick = async (e) => {
    try {
      if (!quoteUrl) {
        return setAlert("Quote has not been created", true, null);
      }

      setLoading((c) => ({ ...c, sendEmail: true }));
      const res = await api.post("/api/quote/send", {
        to: inputs.email,
        subject: `${inputs.invoice ? "Invoice" : "Quote"} from ${c.name}`,
        fileName: `${inputs.invoice ? "Invoice" : "Quote"} for ${inputs.name}.pdf`,
        attachments: c.extras.attachments,
        args: { name: inputs.name, invoice: inputs.invoice },
        template: "quote",
        quoteUrl,
      });

      setAlert(res.data.message, false, null);

      setLoading((c) => ({ ...c, sendEmail: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, sendEmail: false }));
      console.error(e);
    }
  };

  const onDeleteQuoteClick = async (e) => {
    try {
      setLoading((c) => ({ ...c, deleteQuote: true }));
      await deleteQuote(inputs._id);
      setEdit((c) => ({ ...c, quote: false }));
      setInputs({ ...inputsDefault });
      setDetails([]);
      setRef("");
      setLoading((c) => ({ ...c, deleteQuote: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, quote: false }));
      setLoading((c) => ({ ...c, deleteQuote: false }));
      console.error(e);
    }
  };

  const onInvoiceClick = async (e) => {
    try {
      setInputs((c) => ({ ...c, invoice: !c.invoice }));
    } catch (e) {
      console.error(e);
    }
  };

  const onAcceptedClick = async (e) => {
    try {
      setInputs((c) => ({ ...c, accepted: !c.accepted }));
    } catch (e) {
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (!edit.quote) {
        setRef(getRandomString(4));
      }
    } catch (e) {
      console.error(e);
    }
  }, [inputs]);

  useEffect(() => {
    try {
      if (!!search) {
        (async () => {
          const res = await api.get(`/api/quote${search}`);
          const quote = res.data[0];

          if (!!quote) {
            setEdit((c) => ({ ...c, quote: true }));
            setInputs({ _id: quote._id, ...quote.inputs });
            setDetails([...quote.details]);
            setRef(quote.ref);
          } else {
            setEdit((c) => ({ ...c, quote: false }));
            setInputs({ ...inputsDefault });
            setDetails([]);
            setRef("");
          }
        })();
      }
    } catch (e) {
      console.error(e);
    }
  }, [search]);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      setLoading((c) => ({ ...c, saveQuote: true }));
      const req = edit.quote ? putQuote : postQuote;
      await req({
        quoteId: inputs._id,
        invoice: inputs.invoice,
        name: inputs.name,
        postcode: inputs.postcode,
        email: inputs.email,
        tel: inputs.tel,
        ref,
        inputs,
        details,
      });
      setLoading((c) => ({ ...c, saveQuote: false }));
    } catch (e) {
      console.error(e);
      setLoading((c) => ({ ...c, saveQuote: false }));
    }
  };

  // JSX
  return (
    <FullPage>
      <List
        offcanvas={offcanvas}
        toggleOffcanvas={toggleOffcanvas}
        header="Quotes"
        list={quotes}
        headerKey="name"
        page={page}
        onPageClick={onPageClick}
        onClick={onQuoteClick}
      />

      <Section className="below-header custom-page p-t-m">
        <FormGroup>
          <InputGroup>
            <CustomInput
              placeholder="Search"
              name={quoteSearchType}
              value={quoteSearch[quoteSearchType]}
              onChange={onQuoteSearchChange}
            />
            <CustomInput
              type="select"
              value={quoteSearchType}
              onChange={(e) => setQuoteSearchType(e.target.value)}
            >
              <option value="">Select</option>
              {Object.keys(quoteSearchDefault).map((s, i) => (
                <option key={i}>{s}</option>
              ))}
            </CustomInput>
            <CustomButton
              disabled={loading.getQuotes ? true : false}
              color="primary"
              black
              onClick={onSearchClick}
            >
              Search
            </CustomButton>
            <CustomButton color="primary" onClick={(_) => onQuoteClick()}>
              New Quote
            </CustomButton>
          </InputGroup>
        </FormGroup>

        <hr />

        {edit.quote && (
          <>
            <ButtonGroup>
              <CustomButton color="primary" onClick={onInvoiceClick}>
                Turn to {inputs.invoice ? "Quote" : "Invoice"}
              </CustomButton>
              <CustomButton color={inputs.accepted ? "danger" : "success"} onClick={onAcceptedClick}>
                {inputs.accepted ? "Withdrawn" : "Accepted"}
              </CustomButton>
              <CustomButton
                disabled={loading.deleteQuote ? true : false}
                color="danger"
                onClick={onDeleteQuoteClick}
              >
                Delete
              </CustomButton>
            </ButtonGroup>

            <br />
            <br />
          </>
        )}

        <Form onSubmit={onSubmit}>
          <FormGroup floating>
            <CustomInput
              required
              placeholder="Model *"
              type="select"
              name="model"
              value={inputs.model}
              onChange={onInputsChange}
            >
              <option>gpt-3.5-turbo-0125</option>
              <option>gpt-4</option>
            </CustomInput>
            <Label>Model *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Temprature *"
              type="number"
              name="temprature"
              value={inputs.temprature}
              onChange={onInputsChange}
            />
            <Label>Temprature *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Top P *"
              type="number"
              name="top_p"
              value={inputs.top_p}
              onChange={onInputsChange}
            />
            <Label>Top P *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Max Tokens *"
              type="number"
              name="max_tokens"
              value={inputs.max_tokens}
              onChange={onInputsChange}
            />
            <Label>Max Tokens *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Name *"
              type="text"
              name="name"
              value={inputs.name}
              onChange={onInputsChange}
            />
            <Label>Name *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Address *"
              type="text"
              name="address"
              value={inputs.address}
              onChange={onInputsChange}
            />
            <Label>Address *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Postcode *"
              type="text"
              name="postcode"
              value={inputs.postcode}
              onChange={onInputsChange}
            />
            <Label>Postcode *</Label>
          </FormGroup>

          <FormGroup>
            <InputGroup>
              <CustomInput
                required
                type="text"
                name="email"
                value={inputs.email}
                onChange={onInputsChange}
                placeholder="recipient@example.com"
              />
              <CustomButton
                disabled={loading.sendEmail ? true : !quoteUrl ? true : false}
                color="primary"
                black
                onClick={onEmailClick}
              >
                {loading.sendEmail ? <Spinner type="grow" size="sm" /> : "Send Email"}
              </CustomButton>
            </InputGroup>
            <FormText>Email will be sent to {c.email.email1} until hotmail email sending is fixed.</FormText>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Phone *"
              type="number"
              name="tel"
              value={inputs.tel}
              onChange={onInputsChange}
            />
            <Label>Phone *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Estimated Time *"
              type="text"
              name="time"
              value={inputs.time}
              onChange={onInputsChange}
            />
            <Label>Estimated Time *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput required type="select" name="rci" value={inputs.rci} onChange={onInputsChange}>
              <option>yes</option>
              <option>no</option>
            </CustomInput>
            <Label>Rubbish collection included *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              min={0}
              type="number"
              name="instalments"
              value={inputs.instalments}
              onChange={onInputsChange}
            />
            <Label>Instalments *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              min={0}
              max={100}
              type="number"
              name="depositPercentage"
              value={inputs.depositPercentage}
              onChange={onInputsChange}
            />
            <Label>Deposit Percentage *</Label>
          </FormGroup>

          {inputs.invoice && (
            <FormGroup floating>
              <CustomInput
                required
                type="date"
                name="projectDate"
                value={inputs.projectDate}
                onChange={onInputsChange}
              />
              <Label>Project Start Date</Label>
            </FormGroup>
          )}

          <Row>
            <Col md={4}>
              <br />
              <CustomListGroup>
                {details.map((d, i) => (
                  <CustomListGroupItem key={i} onClick={(_) => onDetailClick(d, i)}>
                    <ListGroupItemText>
                      {d?.details?.header}{" "}
                      <CloseButton onClick={(_) => onDeleteDetailClick(i)} style={{ float: "right" }} />
                    </ListGroupItemText>
                  </CustomListGroupItem>
                ))}
              </CustomListGroup>
            </Col>
            <Col md={8}>
              <div className="stick-to-top">
                <br />

                <FormGroup>
                  <Label>Details</Label>
                  <CustomInput
                    placeholder="Details"
                    type="textarea"
                    rows={10}
                    name="details"
                    value={inputs.details}
                    onChange={onInputsChange}
                  />
                </FormGroup>

                <div style={{ float: "right" }}>
                  <ButtonGroup>
                    <CustomButton
                      disabled={loading.generate ? true : false}
                      color="primary"
                      onClick={onGenerateClick}
                    >
                      {loading.generate ? <Spinner type="grow" size="sm" /> : "Generate"}
                    </CustomButton>
                    <CustomButton color="primary" black onClick={onSaveDetailClick}>
                      Save
                    </CustomButton>
                  </ButtonGroup>
                </div>

                {!!generated && typeof generated === "object" ? (
                  <pre>
                    {JSON.stringify(generated, null, 2)}
                    <br />
                  </pre>
                ) : (
                  <></>
                )}
              </div>
            </Col>
          </Row>

          <br />
          <br />

          <CustomButton type="submit" disabled={loading.saveQuote ? true : false} color="primary" black>
            {loading.saveQuote ? <Spinner type="grow" size="sm" /> : "Save"}
          </CustomButton>
          <CustomButton disabled={loading.saveQuote ? true : false} color="primary" onClick={onCreateClick}>
            {loading.saveQuote ? <Spinner type="grow" size="sm" /> : "Create Document"}
          </CustomButton>
          <br />
          <br />
        </Form>

        <div className="p-m" style={{ background: "white" }}>
          <div ref={componentRef}>
            <Quote details={details} inputs={inputs} setInputs={setInputs} id={ref} quoteUrl={quoteUrl} />
          </div>
        </div>

        <br />
        <br />
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({
  quotes: state.quotes,
});

export default connect(mapStateToProps, {
  setAlert,
  getQuotes,
  postQuote,
  putQuote,
  deleteQuote,
})(QuotesPage);
