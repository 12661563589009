///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, memo } from "react";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  ButtonGroup,
  Spinner,
  CustomListGroup,
  CustomListGroupItem,
  Row,
  Col,
  ListGroupItemText,
} from "@ibiliaze/reactstrap";
import { Api } from "@ibiliaze/react-base";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
import getRandomString from "../../utils/random";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = { name: "", quantity: 0, price: 0, id: "" };

const Materials = ({ materials, setInputs }) => {
  // State
  const [materialInputs, setMaterialInputs] = useState({ ...inputsDefault });
  const [details, setDetails] = useState("");
  const [generated, setGenerated] = useState("");
  const [loading, setLoading] = useState({ generate: false });
  const [edit, setEdit] = useState({ material: false });

  // onChange functions
  const onMaterialInputsChange = (e) => setMaterialInputs((c) => ({ ...c, [e.target.name]: e.target.value }));
  const onDetailsChange = (e) => setDetails(e.target.value);

  // onClick functions
  const onGenerateClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, generate: true }));
      const res = await api.post("/api/materials/generate", { details, template: "getMaterials" });
      setGenerated(res.data.materials);
      setLoading((c) => ({ ...c, generate: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, generate: false }));
      console.error(e);
    }
  };

  const onSaveMaterialClick = (_) => {
    try {
      if (!!edit.material) {
        const arr = materials;
        arr[edit.material - 1] = { ...materialInputs };

        setInputs((c) => ({ ...c, materials: [...arr] }));
      } else {
        setInputs((c) => ({
          ...c,
          materials: [...materials, { ...materialInputs, id: getRandomString(4) }],
        }));
      }

      setEdit((c) => ({ ...c, material: false }));
      setMaterialInputs({ ...inputsDefault });
    } catch (e) {
      console.error(e);
    }
  };

  const onSaveMaterialsClick = (_) => {
    try {
      setInputs((c) => ({ ...c, materials: [...materials, ...generated] }));
    } catch (e) {
      console.error(e);
    }
  };

  const onMaterialClick = (m, i) => {
    try {
      setEdit((c) => ({ ...c, material: i + 1 }));
      setMaterialInputs((c) => ({ ...c, ...m }));
      setGenerated(m.materials);
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteMaterialClick = () => {
    try {
      const arr = materials.filter((m) => m?.id !== materialInputs.id);
      setInputs((c) => ({ ...c, materials: [...arr] }));

      setEdit((c) => ({ ...c, material: false }));
      setMaterialInputs({ ...inputsDefault });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <br />
      <h4 className="t-align-c">Materials</h4>

      <Row>
        <Col md={4}>
          <br />
          <CustomListGroup>
            {materials?.map((m, i) => (
              <CustomListGroupItem key={i} onClick={(_) => onMaterialClick(m, i)}>
                <ListGroupItemText>{m?.name} </ListGroupItemText>
              </CustomListGroupItem>
            ))}
          </CustomListGroup>
        </Col>
        <Col md={8}>
          <div className="stick-to-top">
            <br />

            <FormGroup floating>
              <CustomInput
                placeholder="Material"
                name="name"
                value={materialInputs.name}
                onChange={onMaterialInputsChange}
              />
              <Label>Material</Label>
            </FormGroup>

            <FormGroup floating>
              <CustomInput
                placeholder="Quantity"
                type="number"
                name="quantity"
                value={materialInputs.quantity}
                onChange={onMaterialInputsChange}
              />
              <Label>Quantity</Label>
            </FormGroup>

            <FormGroup floating>
              <CustomInput
                placeholder="Price"
                type="number"
                name="price"
                value={materialInputs.price}
                onChange={onMaterialInputsChange}
              />
              <Label>Price</Label>
            </FormGroup>

            <FormGroup>
              {edit.material && (
                <>
                  {materialInputs.id}
                  <br />
                </>
              )}
              <CustomButton color="primary" black onClick={onSaveMaterialClick}>
                {edit.material ? "Update" : "Add"}
              </CustomButton>
              {edit.material && (
                <CustomButton color="danger" onClick={onDeleteMaterialClick}>
                  Delete
                </CustomButton>
              )}
            </FormGroup>

            <hr />

            <h4>AI Generate</h4>

            <FormGroup>
              <CustomInput
                placeholder="Details"
                type="textarea"
                rows={10}
                value={details}
                onChange={onDetailsChange}
              />
            </FormGroup>

            <ButtonGroup style={{ float: "right" }}>
              <CustomButton
                disabled={loading.generate ? true : false}
                color="primary"
                onClick={onGenerateClick}
              >
                {loading.generate ? <Spinner type="grow" size="sm" /> : "Generate"}
              </CustomButton>
              <CustomButton color="primary" black onClick={onSaveMaterialsClick}>
                Add
              </CustomButton>
            </ButtonGroup>

            {!!generated && typeof generated === "object" ? (
              <pre>
                {JSON.stringify(generated, null, 2)}
                <br />
              </pre>
            ) : (
              <></>
            )}
          </div>
        </Col>
      </Row>

      <br />
    </>
  );
};

export default memo(Materials);
