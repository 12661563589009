///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect } from "react";
import { connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Fluency, FullPage, Section } from "@ibiliaze/react-base";
import { Sidebar } from "@ibiliaze/react-sidebar";
import { Col, Row } from "@ibiliaze/reactstrap";
/////////////////////////////////////////////////////////////////////////////////////HOOKS
import useWindowSize from "../../hooks/useWindowSize";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { putAdmin } from "../../actions/admin";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Workers from "../Admin/Workers";
import Contact from "../Admin/Contact";
import Media from "../Admin/Media";
import Payments from "../Admin/Payments";
//////////////////////////////////////////////////////////////////////////////////////////

const AdminPage = ({ admin, putAdmin }) => {
  // State
  const [section, setSection] = useState("Workers");

  // Hooks
  const windowSize = useWindowSize();

  // Navigate
  const navigate = useNavigate();

  // Lifecycle hooks
  useEffect(() => {
    try {
      if (!admin?.isAuthenticated) {
        navigate("/");
      } // eslint-disable-next-line react-hooks/exhaustive-deps
    } catch (e) {
      console.error(e);
    }
  }, []);

  // onSubmit function

  // JSX
  return (
    <FullPage>
      <Section className="below-header custom-page p-t-m">
        <h1 className="no-m">Admin</h1>
        <br />
        <Row>
          <Col md={3} className="p-b-m">
            <Sidebar
              section={section}
              setSection={setSection}
              mobileWidth={580}
              windowWidth={windowSize.width}
              sections={[
                {
                  title: (
                    <>
                      <Fluency name="people" size={40} /> Workers
                    </>
                  ),
                  id: "Workers",
                },
                {
                  title: (
                    <>
                      <Fluency name="phone" size={40} /> Contact
                    </>
                  ),
                  id: "Contact",
                },
                {
                  title: (
                    <>
                      <Fluency name="picture" size={40} /> Media
                    </>
                  ),
                  id: "Media",
                },
                {
                  title: (
                    <>
                      <Fluency name="bank" size={40} /> Payments
                    </>
                  ),
                  id: "Payments",
                },
              ]}
            />
          </Col>
          <Col md={9} className="p-b-m">
            <div className={windowSize.width > 580 ? "user-section" : "user-section-mobile"}>
              <h4 className="t-align-c">{section}</h4>
              <hr />

              {section === "Workers" ? (
                <Workers />
              ) : section === "Media" ? (
                <Media admin={admin} putAdmin={putAdmin} />
              ) : section === "Payments" ? (
                <Payments admin={admin} putAdmin={putAdmin} />
              ) : (
                <Contact admin={admin} putAdmin={putAdmin} />
              )}
            </div>
          </Col>
        </Row>
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({ admin: state.admin });

export default connect(mapStateToProps, { putAdmin })(AdminPage);
