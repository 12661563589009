///////////////////////////////////////////////////////////////////////////////////MODULES
import { memo } from "react";
import { connect } from "react-redux";
////////////////////////////////////////////////////////////////////////////////////STYLES
import * as styles from "./styles";
//////////////////////////////////////////////////////////////////////////////////////////

const Header = ({ admin, inputs, id }) => {
  // JSX
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <img alt="logo" src={admin?.admin?.logo} width="20%" height="20%" />
        <div>
          <div style={{ fontSize: "26px", ...styles.hStyles }}>{admin?.admin?.quoteLlc}</div>
          {admin?.admin?.address?.split("\n")?.map((l, i) => (
            <div key={i}>{l}</div>
          ))}

          <br />
          <div>{admin?.admin?.email1}</div>
          <div>{admin?.admin?.website}</div>
        </div>
        <div>
          <div style={{ fontSize: "26px", ...styles.hStyles }}>
            {inputs?.invoice ? "INVOICE" : inputs?.report ? "REPORT" : "QUOTE"}
          </div>
          <div>
            Date:{" "}
            {new Date().toLocaleDateString("en-GB", { day: "numeric", month: "short", year: "numeric" })}
          </div>
          <div>
            Reference: <b>{id}</b>
          </div>
        </div>
      </div>

      <br />
      <hr style={styles.hrStyles} />
      <br />

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          width: "100%",
        }}
      >
        <div>
          <div style={{ fontSize: "large" }}>
            {inputs?.invoice ? "Invoice for:" : inputs?.report ? "Report for:" : "Quote to:"}
          </div>
          <div>{inputs?.name}</div>
          <div>{inputs?.address}</div>
          <div>{inputs?.postcode}</div>
          <div>United Kingdom</div>
        </div>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({ admin: state.admin });

export default connect(mapStateToProps)(memo(Header));
