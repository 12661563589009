///////////////////////////////////////////////////////////////////////////////////MODULES
import { memo, useState } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  ButtonGroup,
  CustomListGroup,
  CustomListGroupItem,
  InputGroup,
  CustomInputGroupText,
  Row,
  Col,
  ListGroupItemText,
} from "@ibiliaze/reactstrap";
import { DropZone } from "@ibiliaze/react-dropzone";
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "../../actions/alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import getRandomString from "../../utils/random";
import { resolveHost } from "../../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = { name: "", amount: 0, paidBy: "", suppliedBy: "", id: "", imgUrl: "" };

const Receipts = ({ workers, receipts, setAlert, setInputs }) => {
  // State
  const [receiptInputs, setReceiptInputs] = useState(inputsDefault);
  const [edit, setEdit] = useState({ receipt: false });

  // Helper functions
  const onUpload = async (file, receiptId) => {
    await (async () => {
      try {
        setAlert("Uploading image...", false, null, null, true);

        const id = getRandomString(4);

        const img = file[0];
        const formData = new FormData();
        formData.append("image", img);

        const res = await api.post(`api/admin/upload/receipt-${id}-${receiptId}`, formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        if (!res?.data?.secure_url) throw new Error(res?.message || "Failed to upload image");
        const imgPath = res?.data?.secure_url;

        if (!!imgPath && typeof imgPath === "string") setReceiptInputs((c) => ({ ...c, imgUrl: imgPath }));

        setAlert("Receipt uploaded successfully", false, null, null, false);
      } catch (e) {
        console.error(e);
        setAlert(errorParser(e), true, null, null, false);
      }
    })();
  };

  // onClick functions
  const onReceiptClick = (r, i) => {
    try {
      setEdit((c) => ({ ...c, receipt: i + 1 }));
      setReceiptInputs((c) => ({ ...c, ...r }));
    } catch (e) {
      console.error(e);
    }
  };

  const onSaveReceiptClick = async () => {
    try {
      if (!!edit.receipt) {
        const arr = receipts;
        arr[edit.receipt - 1] = { ...receiptInputs };

        setInputs((c) => ({ ...c, receipts: [...arr] }));
      } else {
        setInputs((c) => ({
          ...c,
          receipts: [...receipts, { ...receiptInputs, id: getRandomString(4) }],
        }));
      }

      setEdit((c) => ({ ...c, receipt: false }));
      setReceiptInputs({ ...inputsDefault });
    } catch (e) {
      console.error(e);
    }
  };

  const onDeleteReceiptClick = async (id) => {
    try {
      const arr = receipts.filter((r) => r?.id !== receiptInputs.id);
      setInputs((c) => ({ ...c, receipts: [...arr] }));

      setEdit((c) => ({ ...c, receipt: false }));
      setReceiptInputs({ ...inputsDefault });
    } catch (e) {
      console.error(e);
    }
  };

  // JSX
  return (
    <>
      <br />
      <h4 className="t-align-c">Receipts</h4>

      <Row>
        <Col md={6}>
          <h5 className="t-align-c">Current Receipts</h5>

          <CustomListGroup>
            {receipts?.map((r, i) => (
              <CustomListGroupItem key={i} onClick={(_) => onReceiptClick(r, i)}>
                <ListGroupItemText>
                  £{r?.amount} - {r?.name} - ({r?.paidBy})
                </ListGroupItemText>
              </CustomListGroupItem>
            ))}
          </CustomListGroup>

          <br />
        </Col>

        <Col md={6}>
          <h5 className="t-align-c"> Add a new Receipt</h5>

          <div className="menu stick-to-top">
            <FormGroup floating>
              <CustomInput
                placeholder="Name"
                value={receiptInputs.name}
                onChange={(e) => setReceiptInputs((c) => ({ ...c, name: e.target.value }))}
              />
              <Label>Name</Label>
            </FormGroup>

            <FormGroup>
              <Label>Amount - Paid By - Supplied by</Label>

              <InputGroup>
                <CustomInputGroupText>£</CustomInputGroupText>
                <CustomInput
                  type="number"
                  placeholder="Amount"
                  value={receiptInputs.amount}
                  onChange={(e) => setReceiptInputs((c) => ({ ...c, amount: e.target.value }))}
                />
                <CustomInput
                  type="select"
                  value={receiptInputs.paidBy}
                  onChange={(e) => setReceiptInputs((c) => ({ ...c, paidBy: e.target.value }))}
                >
                  <option value="">Select</option>
                  {workers?.map((w, i) => {
                    if (w.payer) return <option key={i} value={w?.id} label={w?.name} />;
                  })}
                </CustomInput>
                <CustomInput
                  type="select"
                  value={receiptInputs.suppliedBy}
                  onChange={(e) => setReceiptInputs((c) => ({ ...c, suppliedBy: e.target.value }))}
                >
                  <option value="">Select</option>
                  <option>Us</option>
                  <option>Customer</option>
                </CustomInput>
              </InputGroup>
            </FormGroup>

            <Col md={6}>
              <DropZone
                imgSrc={"https://img.icons8.com/3d-fluency/94/bill.png"}
                id={receiptInputs.name}
                onUpload={onUpload}
              />
            </Col>

            <ButtonGroup>
              {!!receiptInputs.imgUrl && (
                <CustomButton color="primary" onClick={(_) => window.open(receiptInputs.imgUrl)}>
                  View Receipt
                </CustomButton>
              )}
              <CustomButton color="primary" black onClick={onSaveReceiptClick}>
                {edit.receipt ? "Update" : "Add"}
              </CustomButton>
              {edit.receipt && (
                <CustomButton color="danger" onClick={(_) => onDeleteReceiptClick(receiptInputs.id)}>
                  Delete
                </CustomButton>
              )}
            </ButtonGroup>
            <br />
          </div>
          <br />
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setAlert })(memo(Receipts));
