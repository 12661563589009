///////////////////////////////////////////////////////////////////////////////////MODULES
import { memo } from "react";
import {
  CustomListGroup,
  CustomListGroupItem,
  Offcanvas,
  OffcanvasHeader,
  OffcanvasBody,
  ListGroupItemText,
  CustomPagination,
} from "@ibiliaze/reactstrap";
//////////////////////////////////////////////////////////////////////////////////////////

const List = ({ offcanvas, toggleOffcanvas, page, onPageClick, onClick, list, headerKey, header }) => {
  // JSX
  return (
    <Offcanvas direction="start" fade scrollable isOpen={offcanvas} toggle={toggleOffcanvas}>
      <OffcanvasHeader toggle={toggleOffcanvas}>{header}</OffcanvasHeader>
      <OffcanvasBody>
        <CustomPagination page={page} onPageClick={onPageClick} />

        <CustomListGroup>
          {list.map((listItem, i) => (
            <CustomListGroupItem key={i} onClick={(_) => onClick(listItem)}>
              <ListGroupItemText>{listItem[headerKey]}</ListGroupItemText>
            </CustomListGroupItem>
          ))}
        </CustomListGroup>
      </OffcanvasBody>
    </Offcanvas>
  );
};

export default memo(List);
