export const hStyles = {
  color: "rgb(66, 77, 95)",
};

export const textStyles = {
  fontFamily: "Segoe UI, sans-serif",
  fontSize: "14px",
  fontWeight: 550,
  color: "rgb(37, 37, 37)",
};

export const hrStyles = {
  backgroundColor: "#d8d8d8",
  border: "none",
  display: "block",
  height: "2px",
  marginLeft: "auto",
  marginRight: "auto",
  width: "60%",
};

export const trStyles = {
  background: "#eeeeff",
};

export const tableStyles = {
  borderCollapse: "separate",
  borderSpacing: "0 40px",
  width: "100%",
};
