////////////////////////////////////////////////////////////////////////////////COMPONENTS
import { CustomCard, Row, Col } from "@ibiliaze/reactstrap";
import { FadeIn, TransformYDown } from "@ibiliaze/react-intersect";
import { Section } from "@ibiliaze/react-base";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Reviewers = () => {
  // onClick functions
  const onShareClick = (_) => {
    try {
      navigator.clipboard
        .writeText(`${c.extras.checkatrade.link}?utm_medium=share%26utm_source=copy_link`)
        .then(() => {});
    } catch (e) {
      console.error("Failed to copy to clipboard");
    }
  };

  // JSX
  return (
    <Section className="custom-page p-t-xl p-b-xl">
      <hr />
      <FadeIn>
        <img src={c.extras.checkatradeLogoPic} alt="images" className="ct-logo" />
      </FadeIn>
      <br />
      <br />
      <Row>
        <Col md={3}>
          <TransformYDown>
            <CustomCard
              imgSrc={c.extras.logoPic}
              title={c.extras.checkatrade.name}
              titleTag="h6"
              text={c.extras.checkatrade.rating}
              multiclick={false}
              onCardClickHandler={(_) => window.open(c.extras.checkatrade.link)}
            />
          </TransformYDown>
        </Col>
        <Col md={9}>
          <TransformYDown>
            <CustomCard
              title="Read Our Reviews"
              titleTag="h6"
              multiclick={false}
              onCardClickHandler={(_) => window.open(`${c.extras.checkatrade.link}/reviews`)}
            />
          </TransformYDown>
          <TransformYDown>
            <CustomCard
              title="Leave Feedback"
              titleTag="h6"
              multiclick={false}
              onCardClickHandler={(_) =>
                window.open(
                  `https://www.checkatrade.com/give-feedback/trades/${c.extras.checkatrade.urlName}`
                )
              }
            />
          </TransformYDown>
          <TransformYDown>
            <CustomCard
              title="Share Our Trade"
              titleTag="h6"
              clickedTitle="Copied Checkatrade link to clipboard"
              multiclick={true}
              onCardClickHandler={onShareClick}
            />
          </TransformYDown>
          <TransformYDown>
            <CustomCard
              title="View Our Extended Gallery"
              titleTag="h6"
              multiclick={false}
              onCardClickHandler={(_) => window.open(`${c.extras.checkatrade.link}}#gallery`)}
            />
          </TransformYDown>
        </Col>
      </Row>
    </Section>
  );
};

export default Reviewers;
