///////////////////////////////////////////////////////////////////////////////////MODULES
import { useState, useEffect, useRef } from "react";
import { connect } from "react-redux";
import {
  FormGroup,
  Label,
  CustomButton,
  CustomInput,
  Spinner,
  Form,
  InputGroup,
  CustomListGroupItem,
  ListGroupItemHeading,
  CustomListGroup,
  ButtonGroup,
} from "@ibiliaze/reactstrap";
import { FullPage, Section } from "@ibiliaze/react-base";
import { Api } from "@ibiliaze/react-base";
import { saveAs } from "file-saver";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { getProjects, postProject, putProject, deleteProject } from "../../actions/projects";
import { getQuotes } from "../../actions/quotes";
import { getWorkers } from "../../actions/workers";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import { resolveHost } from "../../utils/resolveEnv";
////////////////////////////////////////////////////////////////////////////////COMPONENTS
import Receipts from "../Project/Receipts";
import Workers from "../Project/Workers";
import Materials from "../Project/Materials";
import Report from "../PDF/Report";
import List from "../Layout/List";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const inputsDefault = {
  projectName: "",
  quoteRef: "",
  startDate: "",
  name: "",
  address: "",
  postcode: "",
  details: "",
  price: 0,
  workers: [],
  receipts: [],
  materials: [],
  completed: false,
};
const reportDefault = {
  salaryTotals: { payers: {}, total: 0 },
  receiptTotals: { payers: {}, totalWeSupply: 0, totalCustomerSupplies: 0 },
};

const ProjectsPage = ({
  workers,
  projects,
  quotes,
  getWorkers,
  getProjects,
  postProject,
  putProject,
  deleteProject,
  getQuotes,
}) => {
  // State
  const [page, setPage] = useState(1);
  const [offcanvas, setOffcanvas] = useState(false);
  const [projectList, setProjectsList] = useState(true);
  const [loading, setLoading] = useState({
    saveProject: false,
    getProjects: false,
    deleteProject: false,
    getQuotes: false,
    calculate: false,
  });
  const [edit, setEdit] = useState({ project: false });
  const [projectNameSearch, setProjectNameSearch] = useState("");
  const [inputs, setInputs] = useState({ ...inputsDefault });
  const [quoteRefSearch, setQuoteRefSearch] = useState("");
  const [report, setReport] = useState({ ...reportDefault });

  // Ref
  const componentRef = useRef();

  // Toggle functions
  const toggleOffcanvas = () => setOffcanvas(!offcanvas);

  // onChange functions
  const onInputsChange = (e) => setInputs((c) => ({ ...c, [e.target.name]: e.target.value }));
  const onProjectNameSearchChange = (e) => setProjectNameSearch(e.target.value);
  const onQuoteRefSearchChange = (e) => setQuoteRefSearch(e.target.value);

  // onClick functions
  const onPageClick = async (e, index) => {
    try {
      e.preventDefault();

      setLoading((c) => ({ ...c, getProjects: true }));
      const goToPage = index < 1 ? 1 : index;

      setPage(goToPage);
      await getProjects();
      setLoading((c) => ({ ...c, getProjects: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getProjects: false }));
      console.error(e);
    }
  };

  const onSearchClick = async (e) => {
    try {
      setProjectsList(true);
      setLoading((c) => ({ ...c, getProjects: true }));
      await getProjects();
      toggleOffcanvas();
      setLoading((c) => ({ ...c, getProjects: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getProjects: false }));
      console.error(e);
    }
  };

  const onSearchQuotesClick = async (_) => {
    try {
      setProjectsList(false);
      setLoading((c) => ({ ...c, getQuotes: true }));
      await getQuotes(`?ref=${quoteRefSearch}&limit=10&skip=${10 * (page - 1)}`);
      toggleOffcanvas();
      setLoading((c) => ({ ...c, getQuotes: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, getQuotes: false }));
      console.error(e);
    }
  };

  const onProjectClick = (project) => {
    try {
      if (!!project) {
        setEdit((c) => ({ ...c, project: true }));
        setInputs({ ...project });
      } else {
        setEdit((c) => ({ ...c, project: false }));
        setInputs({ ...inputsDefault });
      }
      toggleOffcanvas();
    } catch (e) {
      console.error(e);
      setEdit((c) => ({ ...c, project: false }));
      setInputs({ ...inputsDefault });
      toggleOffcanvas();
    }
  };

  const onQuoteClick = (quote) => {
    try {
      setInputs((c) => ({
        ...c,
        quoteRef: quote?.ref,
        name: quote?.name,
        address: quote?.inputs?.address,
        postcode: quote?.postcode,
      }));
      toggleOffcanvas();
    } catch (e) {
      console.error(e);
      toggleOffcanvas();
    }
  };

  const onSelectedQuoteClick = () => {
    try {
      window.open(`/quotes?ref=${inputs.quoteRef}`);
    } catch (e) {
      console.error(e);
    }
  };

  const onCompletedClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, saveProject: true }));
      setInputs((c) => ({ ...c, completed: !inputs.completed }));
      await putProject({ ...inputs, projectId: inputs._id, completed: !inputs.completed });
      setLoading((c) => ({ ...c, saveProject: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveProject: false }));
      console.error(e);
    }
  };

  const onDeleteProjectClick = async () => {
    try {
      setLoading((c) => ({ ...c, deleteProject: true }));
      await deleteProject(inputs._id);
      setEdit((c) => ({ ...c, project: false }));
      setInputs({ ...inputsDefault });
      setLoading((c) => ({ ...c, deleteProject: false }));
    } catch (e) {
      setEdit((c) => ({ ...c, project: false }));
      setLoading((c) => ({ ...c, deleteProject: false }));
      console.error(e);
    }
  };

  const onCreateClick = async (_) => {
    try {
      setLoading((c) => ({ ...c, saveProject: true }));

      const fileName = `Report for ${inputs.projectName}`;

      const res = await api.post("/api/pdf/create", {
        ...inputs,
        dom: componentRef.current.innerHTML,
        fileName,
      });

      const fileUrl = res.data.url;
      const response = await fetch(fileUrl);
      const blob = await response.blob();
      saveAs(blob, fileName);

      setLoading((c) => ({ ...c, saveProject: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveProject: false }));
      console.error(e);
    }
  };

  const onCalculateClick = (_) => {
    try {
      setLoading((c) => ({ ...c, calculate: true }));

      const salaryTotals = { ...reportDefault.salaryTotals };
      const receiptTotals = { ...reportDefault.receiptTotals };
      salaryTotals.payers = {};
      receiptTotals.payers = {};

      // Calculate salaries
      inputs.workers.forEach((entry) => {
        const salary = entry.rate * entry.days;

        salaryTotals.total = salaryTotals.total += salary;

        if (entry.payer) {
          if (salaryTotals.payers[entry.payer]) {
            salaryTotals.payers[entry.payer] += salary;
          } else {
            salaryTotals.payers[entry.payer] = salary;
          }
        }
      });

      // Calculate receipts
      inputs.receipts.forEach((entry) => {
        const amount = Number(entry.amount);
        const payer = entry.paidBy;

        receiptTotals.totalWeSupply =
          entry.suppliedBy === "Us"
            ? (receiptTotals.totalWeSupply += amount)
            : (receiptTotals.totalWeSupply += 0);

        receiptTotals.totalCustomerSupplies =
          entry.suppliedBy === "Customer"
            ? (receiptTotals.totalCustomerSupplies += amount)
            : (receiptTotals.totalCustomerSupplies += 0);

        if (receiptTotals.payers[payer]) {
          receiptTotals.payers[payer] += amount;
        } else {
          receiptTotals.payers[payer] = amount;
        }
      });

      setReport({ salaryTotals: { ...salaryTotals }, receiptTotals: { ...receiptTotals } });

      setLoading((c) => ({ ...c, calculate: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, calculate: false }));
      console.error(e);
    }
  };

  // Lifecycle hooks
  useEffect(() => {
    try {
      (async () => {
        await getWorkers();
      })();
    } catch (e) {
      console.error(e);
    }
  }, []);

  // onSubmit function
  const onSubmit = async (e) => {
    e.preventDefault();

    try {
      const req = edit.project ? putProject : postProject;

      setLoading((c) => ({ ...c, saveProject: true }));
      await req({ ...inputs, projectId: inputs._id });
      setLoading((c) => ({ ...c, saveProject: false }));
    } catch (e) {
      setLoading((c) => ({ ...c, saveProject: false }));
      console.error(e);
    }
  };

  // JSX
  return (
    <FullPage>
      <List
        offcanvas={offcanvas}
        toggleOffcanvas={toggleOffcanvas}
        header={!projectList ? "Quotes" : "Projects"}
        list={!projectList ? quotes : projects}
        headerKey={!projectList ? "name" : "projectName"}
        page={page}
        onPageClick={onPageClick}
        onClick={!projectList ? onQuoteClick : onProjectClick}
      />

      <Section className="below-header custom-page p-t-m">
        <FormGroup>
          <InputGroup>
            <CustomInput
              placeholder="Search"
              value={projectNameSearch}
              onChange={onProjectNameSearchChange}
            />
            <CustomButton
              disabled={loading.getProjects ? true : false}
              color="primary"
              black
              onClick={onSearchClick}
            >
              {loading.getProjects ? <Spinner type="grow" size="sm" /> : "Search"}
            </CustomButton>
            <CustomButton color="primary" onClick={(_) => onProjectClick()}>
              New Project
            </CustomButton>
          </InputGroup>
        </FormGroup>

        <hr />

        <Form onSubmit={onSubmit}>
          <FormGroup>
            <InputGroup>
              <CustomInput
                required
                placeholder="Project Name *"
                name="projectName"
                value={inputs.projectName}
                onChange={onInputsChange}
              />
              {edit.project && (
                <CustomButton color={inputs.completed ? "danger" : "success"} onClick={onCompletedClick}>
                  {inputs.completed ? "Incompleted" : "Completed"}
                </CustomButton>
              )}
            </InputGroup>
          </FormGroup>

          <FormGroup>
            <InputGroup>
              <CustomInput
                placeholder="Quote Ref"
                name="quoteRef"
                value={quoteRefSearch}
                onChange={onQuoteRefSearchChange}
              />
              <CustomButton color="primary" black onClick={onSearchQuotesClick}>
                Search
              </CustomButton>
            </InputGroup>
          </FormGroup>

          {!!inputs?.quoteRef && typeof inputs?.quoteRef === "string" && (
            <FormGroup>
              <CustomListGroup>
                <CustomListGroupItem onClick={onSelectedQuoteClick}>
                  <ListGroupItemHeading>{inputs.quoteRef}</ListGroupItemHeading>
                </CustomListGroupItem>
              </CustomListGroup>
            </FormGroup>
          )}

          <FormGroup floating>
            <CustomInput
              placeholder="Customer Name"
              name="name"
              value={inputs.name}
              onChange={onInputsChange}
            />
            <Label>Customer Name</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              placeholder="Address"
              name="address"
              value={inputs.address}
              onChange={onInputsChange}
            />
            <Label>Address</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              placeholder="Postcode"
              name="postcode"
              value={inputs.postcode}
              onChange={onInputsChange}
            />
            <Label>Postcode</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Price *"
              type="number"
              name="price"
              value={inputs.price}
              onChange={onInputsChange}
            />
            <Label>Price *</Label>
          </FormGroup>

          <FormGroup floating>
            <CustomInput
              required
              placeholder="Start Date *"
              type="date"
              name="startDate"
              value={inputs.startDate}
              onChange={onInputsChange}
            />
            <Label>Start Date *</Label>
          </FormGroup>

          <Workers workers={workers} selectedWorkers={inputs?.workers} setInputs={setInputs} />

          <Receipts workers={workers} receipts={inputs?.receipts} setInputs={setInputs} />

          <Materials materials={inputs?.materials} setInputs={setInputs} />

          <ButtonGroup>
            <CustomButton
              disabled={loading.deleteProject ? true : false}
              color="danger"
              onClick={onDeleteProjectClick}
            >
              Delete
            </CustomButton>
            <CustomButton disabled={loading.saveProject ? true : false} color="primary" black type="submit">
              {loading.saveProject ? <Spinner type="grow" size="sm" /> : "Save"}
            </CustomButton>
          </ButtonGroup>

          <br />
          <br />
        </Form>

        {edit.project && (
          <>
            <ButtonGroup>
              <CustomButton
                disabled={loading.calculate ? true : false}
                color="primary"
                onClick={onCalculateClick}
              >
                {loading.calculate ? <Spinner type="grow" size="sm" /> : "Calculate"}
              </CustomButton>
            </ButtonGroup>

            <br />
            <br />

            <div className="p-m" style={{ background: "white" }}>
              <div ref={componentRef}>
                <Report inputs={{ ...inputs, report: true }} report={report} id={inputs.quoteRef} />
              </div>
            </div>

            <br />

            <CustomButton
              disabled={loading.saveProject ? true : false}
              color="primary"
              black
              onClick={onCreateClick}
            >
              {loading.saveProject ? <Spinner type="grow" size="sm" /> : "Create Document"}
            </CustomButton>

            <br />
            <br />
          </>
        )}
      </Section>
    </FullPage>
  );
};

const mapStateToProps = (state) => ({
  projects: state.projects,
  workers: state.workers,
  quotes: state.quotes,
});

export default connect(mapStateToProps, {
  getProjects,
  postProject,
  putProject,
  deleteProject,
  getWorkers,
  getQuotes,
})(ProjectsPage);
