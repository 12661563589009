////////////////////////////////////////////////////////////////////////////////////STYLES
import * as styles from "./styles";
//////////////////////////////////////////////////////////////////////////////////////////

const Details = ({ details }) => {
  // JSX
  return (
    <>
      <thead>
        <tr style={styles.trStyles}>
          <th>
            <div style={{ textAlign: "left", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
              Description
            </div>
          </th>
          <th style={{ textAlign: "right", padding: "10px", ...styles.textStyles, fontSize: "17px" }}>
            <div>Rate</div>
          </th>
        </tr>
      </thead>
      <tbody>
        {!!details &&
          Array.isArray(details) &&
          details.map((det, i) => (
            <tr key={i}>
              <td>
                <div
                  style={{ textAlign: "center", fontSize: "17px", ...styles.hStyles, paddingBottom: "10px" }}
                >
                  {det?.details?.header}
                </div>
                {det?.details?.quote?.map((line, i) => (
                  <li key={i}>{line}</li>
                ))}
              </td>
              <td style={{ textAlign: "right", verticalAlign: "top" }}>
                <b>£{Number(det?.details?.price).toFixed(2)}</b>
              </td>
            </tr>
          ))}
      </tbody>
    </>
  );
};

export default Details;
