///////////////////////////////////////////////////////////////////////////////////MODULES
import { connect } from "react-redux";
import { Col, Row } from "@ibiliaze/reactstrap";
import { DropZone } from "@ibiliaze/react-dropzone";
import { Api } from "@ibiliaze/react-base";
import errorParser from "@ibiliaze/http-error-parser";
///////////////////////////////////////////////////////////////////////////////////ACTIONS
import { setAlert } from "../../actions/alert";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
import { resolveHost } from "../../utils/resolveEnv";
///////////////////////////////////////////////////////////////////////////////////////API
const api = new Api(resolveHost()).api;
//////////////////////////////////////////////////////////////////////////////////////////

const Media = ({ admin, putAdmin, setAlert }) => {
  // Helper functions
  const onUpload = async (file, comparisonId) => {
    try {
      setAlert("Uploading image...", false, null, null, true);

      const img = file[0];
      const formData = new FormData();
      formData.append("image", img);

      const res = await api.post(`api/admin/upload/${comparisonId}`, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (!res?.data?.secure_url) throw new Error(res?.message || "Failed to upload image");

      const imgPath = res?.data?.secure_url;

      await putAdmin({ comparisons: { ...admin?.admin?.comparisons, [comparisonId]: imgPath } });
    } catch (e) {
      console.error(e);
      setAlert(errorParser(e), true, null, null, false);
    }
  };

  // JSX
  return (
    <>
      <Row>
        <Col md={6}>
          <h4 className="t-align-c">Comparison Image 1 - Before</h4>
          <DropZone
            onUpload={onUpload}
            id="comparison-1"
            imgSrc={admin?.admin?.comparisons?.["comparison-1"] || c.extras.albumPlaceholderPic}
          />
        </Col>

        <Col md={6}>
          <h4 className="t-align-c">Comparison Image 1 - After</h4>
          <DropZone
            onUpload={onUpload}
            id="comparison-2"
            imgSrc={admin?.admin?.comparisons?.["comparison-2"] || c.extras.albumPlaceholderPic}
          />
        </Col>

        <Col md={6}>
          <h4 className="t-align-c">Comparison Image 2 - Before</h4>
          <DropZone
            onUpload={onUpload}
            id="comparison-3"
            imgSrc={admin?.admin?.comparisons?.["comparison-3"] || c.extras.albumPlaceholderPic}
          />
        </Col>

        <Col md={6}>
          <h4 className="t-align-c">Comparison Image 2 - After</h4>
          <DropZone
            onUpload={onUpload}
            id="comparison-4"
            imgSrc={admin?.admin?.comparisons?.["comparison-4"] || c.extras.albumPlaceholderPic}
          />
        </Col>
      </Row>
    </>
  );
};

export default connect(null, { setAlert })(Media);
