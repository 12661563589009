////////////////////////////////////////////////////////////////////////////////////STYLES
import * as styles from "./styles";
/////////////////////////////////////////////////////////////////////////////////////UTILS
import c from "../../utils/constants";
//////////////////////////////////////////////////////////////////////////////////////////

const Footer = ({ inputs, admin }) => {
  // JSX
  return (
    <>
      <div style={{ textAlign: "center", fontSize: "20px", ...styles.hStyles }}>Terms and Conditions</div>
      <br />
      <li>
        {c.name} will issue a Warranty Certificate upon completing the project date that is valid for 1 year
      </li>
      <li>Rubbish collection is{inputs?.rci === "no" ? " not" : ""} included in this price</li>
      <li>
        Visible materials should be provided by the customer. However, {c.name} can assist in sourcing visible
        materials with trade discounts, if preferred
      </li>
      <li>The entire project is estimated to be completed within {inputs?.time}</li>

      <br />
      <br />
      <div style={{ textAlign: "center", fontSize: "20px", ...styles.hStyles }}>Payment Terms</div>
      <br />
      {inputs?.instalments > 0 ? (
        <>
          <li>Deposit of {inputs?.depositPercentage}% must be paid to book the project date</li>
          <li>The payment will be split into {inputs?.instalments} instalments, after the deposit is paid</li>
          <li>Your first payment must be made on the 2nd day of the project</li>
        </>
      ) : (
        <li>The payment will be taken once the project has been completed</li>
      )}

      <br />

      {inputs?.invoice && (
        <div style={{ ...styles.hStyles, fontSize: "16px" }}>
          <hr />
          <div>Account Name: {admin?.admin?.bankAccName}</div>
          <div>Account Number: {admin?.admin?.bankAccNumber}</div>
          <div>Sort Code: {admin?.admin?.bankSortcode}</div>
        </div>
      )}
    </>
  );
};

export default Footer;
